/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesHeroic: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Heroic Gauntlet</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_heroic.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Heroic Gauntlet</h1>
          <h2>Heroic Gauntlet guide for AFK Journey!</h2>
          <p>
            Last updated: <strong>26/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Difficulty 7 Teams" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Whale Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Whale Team (Lily May & Phraesto)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/heroic_1.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover />{' '}
              </li>
              <li>
                <AFKCharacter mode="inline" slug="damian" enablePopover />
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Surging Spell" />
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - F2P Team</>}
            className="with-bg"
          >
            <h5>Team #2 - F2P Team (Odie & Thoran )</h5>
            <StaticImage
              src="../../../images/afk/dream_s/heroic_2.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover />{' '}
              </li>
              <li>
                <AFKCharacter mode="inline" slug="damian" enablePopover />
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Surging Spell" />
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - F2P Team</>}
            className="with-bg"
          >
            <h5>Team #3 - F2P Team (Marilee & Hodgkin)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/heroic_3.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="marilee" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="hodgkin" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover />{' '}
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover />
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Surging Spell" />
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={<>Team #4 - F2P Team</>}
            className="with-bg"
          >
            <h5>Team #4 - F2P Team (Odie & Brutus)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/heroic_4.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="hugin" enablePopover />{' '}
              </li>
              <li>
                <AFKCharacter mode="inline" slug="brutus" enablePopover />
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Surging Spell" />
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Video" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="MVhpmfvOblU" />
          </Col>
        </Row>
        <SectionHeader title="Challenger Teams" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab eventKey="Team1" title={<>Team #1</>} className="with-bg">
            <h5>Team #1</h5>
            <StaticImage
              src="../../../images/afk/dream_s/heroic_5.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="valka" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="hodgkin" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="florabelle" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover />{' '}
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover />
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Surging Spell" />
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab eventKey="Team2" title={<>Team #2</>} className="with-bg">
            <h5>Team #2</h5>
            <StaticImage
              src="../../../images/afk/dream_s/heroic_6.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="brutus" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="florabelle" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover />{' '}
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover />
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Harmonic Spell" />
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab eventKey="Team3" title={<>Team #3</>} className="with-bg">
            <h5>Team #3</h5>
            <StaticImage
              src="../../../images/afk/dream_s/heroic_7.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="igor" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="bryon" enablePopover />
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover />
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Surging Spell" />
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab eventKey="Team4" title={<>Team #4</>} className="with-bg">
            <h5>Team #4</h5>
            <StaticImage
              src="../../../images/afk/dream_s/heroic_8.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="valka" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="brutus" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="hugin" enablePopover />{' '}
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Surging Spell" />
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesHeroic;

export const Head: React.FC = () => (
  <Seo
    title="Heroic Gauntlet | AFK Journey | Prydwen Institute"
    description="Heroic Gauntlet guide for AFK Journey!"
    game="afk"
  />
);
